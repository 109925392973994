.main {
  font-family: "e-Ukraine-Regular", sans-serif;
}
.background-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./Images/mainbg.png);
  background-size: cover;
}
.main__container {
  position: relative;
  
}
.main__logo {
  margin-top: 30px;
}
.rotating__svg {
  position: absolute;
  width: 20%;
  top: -213px;
  left: 0;
  animation: rotate 15s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main__btns {
  display: flex;
  float: 0 0 auto;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 30px;
}

.sort {
  background-color: #6a6a6a;
  color: aliceblue;
  padding: 14px 52px;
  cursor: pointer;
  border: none;
  font-size: 30px;
  border: 2px solid white;
  transition: 0.3s ease, 0.3s ease;
  
}
.sort:hover{
  background-color: #3b3b3b;
}
.sort_button {
  text-align: right; /* Вирівнює кнопку зліва */
}
.main__search input {
  width: 100%;
  border-radius: 50px;
  margin-bottom: 30px;
  height: 50px;
  border: none;
  padding-left: 50px;
  font-size: 20px;
  box-sizing: border-box;
}
.main__search input::placeholder {
  font-size: 20px;
}
.main__victim {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 20px;
  margin-top: 30px;
  padding-bottom: 50px;
}
.main__victim-link {
  display: block;
}

.main__victim-item {
  justify-content: center;
  border: 3px solid transparent;
  transition: border-color 0.3s ease, 0.3s ease;
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
  max-width: 275px;
  height: 275px;
}
.main__victim-item:hover {
  border: 3px solid red;
}

.main__victim-item img {
  width: 100%;
  height: 275px;
  object-fit: cover;
}
.main__footer {
  display: flex;
  color: aliceblue;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  gap: 20px;
  font-size: 32px;
}
.main__link {
  display: block;
  padding-top: 12px;
}
.main__link:hover svg path {
  stroke: red;
}
.main__link:hover svg path:nth-child(2) {
  fill: red;
}
.nothing {
  display: block;
  margin-top: 100px;
  color: aliceblue;
  margin-bottom: 315px;
  font-size: 40px;
  height: 100vh;
}

.main__inf {
  color: white;
}

.main__inf a {
  color: white;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-bottom: 50px;
  transition: all 0.3s ease;
}
.main__inf a:hover {
  color: red;
}
.main__search {
  position: relative;
}

.main__search-icon {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 7px;
  left: 7px;
}
.main__title {
  color: white;
  margin-bottom: 40px;
}
.main__read-more-button {
  width: auto;
}

.main__victim-item {
  position: relative;
}

.main__victim-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  font-size: 10px;
  transition: opacity 0.3s ease;
}
.main__victim-details p:first-child {
  font-weight: bold;
}

.main__victim-item:hover .main__victim-details {
  opacity: 1;
}

@media (max-width: 1228px) {
  .main__btns {
    flex-direction: column;
  }
}
@media (max-width: 1070px) {
  .main__victim-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    box-sizing: border-box;
    opacity: 1;
    font-size: 10px bold;
  }
}

@media (max-width: 992px) {
  .main__victim {
    grid-template-columns: auto auto auto;
  }
}

@media (max-width: 768px) {
  .main__victim {
    grid-template-columns: auto auto;
    justify-content: center;
  }
  .main__btns {
    flex-direction: column;
    margin-bottom: 0;
  }
  .main__victim-item {
    position: relative;
  }
}

@media (max-width: 679px) {
  .main__btns-help,
  .main__btns-war {
    font-size: 23px;
  }
}
@media (max-width: 505px) {
  .main__btns-help,
  .main__btns-war {
    font-size: 19px;
    margin-bottom: 20px;
  }
}
@media (max-width: 439px) {
  .main__btns-help,
  .main__btns-war {
    font-size: 16px;
  }
}
@media (max-width: 389px) {
  .main__btns-help,
  .main__btns-war {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .main__victim {
    grid-template-columns: 300px;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }
  .main__search input {
    height: 37px;
    padding-left: 30px;
    margin-bottom: 0;
  }
  .main__search input::placeholder {
    font-size: 17px;
  }
  .main__btns {
    margin-top: 0;
  }
  .main__search-icon {
    display: none;
  }
}
@media (max-width: 400px) {
  .main__victim {
    grid-template-columns: 250px;
  }
  .main__footer {
    font-size: 25px;
    padding-bottom: 20px;
  }

  .main__title {
    font-size: 16px;
  }
}
@media (max-width: 1228px) {
  .sort {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .sort {
    padding: 10px 50px;
  }
}
@media (max-width: 768px) {
  .sort {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 625px) {
  .sort {
    margin-top: 20px;
    padding: 7px 20px;
    font-size: 16px;
  }
}
@media (max-width: 389px) {
  .sort {
   
    font-size: 12px;
  }
}