/* styles.css */
.typewriter-text {
  color: red;
  font-size: 56px;
  display: inline-block;
}

.cursor-blink {
  display: inline-block;
  width: 8px;
  height: 18px;
  color: red;
  animation: blink-caret 0.95s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@media (max-width: 575px) {
  .typewriter-text {
    margin-bottom: 30px;
  }
}
@media (max-width: 545px) {
  .typewriter-text {
    font-size: 36px;
  }
}
@media (max-width: 400px) {
  .typewriter-text {
    font-size: 26px;
  }
}
