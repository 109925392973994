.header__mobile {
  position: fixed;
  visibility: hidden;
  overflow: hidden;
  transform: translate3d(110vw, 0, 0);
  transition: visibility 0s linear 800ms,
  transform 800ms cubic-bezier(0.535, 0, 0, 1) 0s;
  background-color: #27231b;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 7;
  text-align: center;
  padding-top: 140px;
}

.menu__btn {
  display: none;
}

.menu__btn span {
  color: rgb(255, 255, 255);
  width: 100%;
  height: 3px;
  transition: transform 0.3s ease;
}

@media (max-width: 1000px) {
  .menu__btn {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 32px;
    background-color: transparent;
    border: none;
    justify-content: space-between;
    z-index: 10;
    transition: transform 0.3s ease;
  }
  .header__mobile {
    margin: 0;
  }
  .active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: visibility 0s linear 0s,
    transform 800ms cubic-bezier(0.535, 0, 0, 1) 0s;
  }
  .header__mobile .menu__item a {
    color: rgb(255, 255, 255);
    font-size: 3vh;
    padding: 20px 0;
  }
  .header__mobile .menu__item {
    padding: 20px 0;
  }

  .menu__btn span {
    background-color: white;
  }
}

.menu--open span:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
  transition: transform 0.3s ease;
}

.menu--open span:nth-child(2) {
  display: none;
}

.menu--open span:nth-child(3) {
  transform: translateY(-20px) rotate(-45deg);
  transition: transform 0.3s ease;
}
