.breadcrumbs {
  display: flex;
  white-space: nowrap;
  margin-top: 40px;
  margin-bottom: 50px;
  align-items: center;
  transition: all 0.1s;
}
.breadcrumbs:hover {
  color: red;
}

.link {
  align-items: center;
  height: 20px;
  display: flex;
  gap: 5px;
}
.breadcrumbs a {
  align-self: center;
}
