[class$="__container"] {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  text-align: center;
}
a,
li {
  list-style: none;
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box;
  background-image: url(./Images/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "e-Ukraine-Regular", sans-serif;
}

.logo {
  display: block;
  margin-bottom: 30px;
}
.logo svg {
  transition: all .5s;
}
.logo svg:hover{
  scale: 1.05;
}
.victim__content {
  display: flex;
}
.victim__img {
  width: 400px;
  margin-right: 50px;
  transition: all .5s;
}
.victim__img:hover{
scale: 1.01;
}

.img {
  width: 400px;
  margin-bottom: 20px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.victim__life {
  font-size: 40px;
  text-transform: uppercase;
}

.victim__text p {
  margin-bottom: 30px;
  font-size: 24px;
  text-align: left;
}

.petition {
  font-size: 30px;
  margin-bottom: 20px;
  display: block;
  background-color: rgba(255, 0, 0, 0.267);
  transition: all 0.5s;
  padding: 7px;
  border-radius: 30px;
}

.petition:hover {
  background-color: rgba(255, 0, 0, 0.425);
}
@media (max-width: 1100px) {
  .petition{font-size: 22px;
}}
@media (max-width: 992px) {
  .victim__life {
    font-size: 28px;
  }
}
@media (max-width: 900px) {
  .victim__content {
    flex-direction: column;
  }
  .victim__img {
    margin: 0 auto;
  }
}
@media (max-width: 535px) {
  .img {
    width: 90%;
  }
  .victim__life {
    font-size: 24px;
  }
  .victim__text p {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .petition{font-size: 18px;
  }
}
@media (max-width: 473px) {
  .victim__img {
    width: 300px;
  }
  .petition{font-size: 15px;
  }
}
@media (max-width: 383px) {
  .victim__img {
    width: 230px;
  }
  .logo {
    margin-bottom: 0;
  }
  .petition{font-size: 11px;
  }
}
