body {
  background-size: cover;
}
.petition__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.petition__title {
  text-align: start;
 
}
.petition__text {
  text-align: start;
}

.petition__text a{
  color: red;
}
.petition__text p {
  margin-bottom: 20px;
 }
.petition-link {
  color: red;
  transition: all 0.5s;
  display: block;
}
.petition__link:hover {
  color: rgb(99, 2, 2);
}
.petition-btn {
  margin-top: auto;
  margin-bottom: 30px;
}
/*@media (max-width: 1100px) {
  .petition__container{
    height: auto;
  }
}*/
@media (max-width: 750px) {
  .Petition__container {
    font-size: 14px;
  }
  .petition__title {
    margin-top: 40px;
  }
}
@media (max-width: 420px) {
  .petition__title {
    margin-top: 20px;
    font-size: 16px;
  }
  .petition__text {
    font-size: 14px;
  }
}
