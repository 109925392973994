.footer__inf {
  color: white;
}
.footer__menu {
  color: white;
  text-align: center;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.footer__menu .menu__list {
  justify-content: center;
  padding: 0;
}

.footer__copy {
  display: flex;
  align-items: center;
  color: white;
  font-size: 11px;
  text-align: center;
  justify-content: center;
  padding-bottom: 30px;
}
.copy{
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 770px) {
  .footer__menu .menu__list {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .footer__inf {
    font-size: 10px;
  }
}
