.proekt__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.proekt__link{
margin-top: auto;
margin-bottom: 30px;}


.proekt__title {
  text-align: start;
}
.proekt__text {
  margin-bottom: 50px;
}
.proekt__text p {
  text-align: start;
  margin-bottom: 20px;
}

.proekt-btn{
margin-top: auto;
margin-bottom: 30px;
}
@media (max-width: 1000px) {
  .proekt__container{
    height: auto;
  }
}

@media (max-width: 550px) {
  .proekt__text {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .proekt__text {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .proekt__text {
    font-size: 14px;
  }
}
