.img {
  display: block;
  padding-top: 30px;
  transition: all 0.5s;
}

@media (max-width: 576px) {
  .img svg {
    width: 90%;
  }
}
@media (max-width: 400px) {
  .img {
    padding-top: 0;
    height: 100px;
  }
}
