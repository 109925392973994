.video__container {
  margin: 0 auto;
 
  
}

.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
}

.video__text {
  font-size: 40px;
  transition: all 0.3s ease;
}
.video__text:hover {
  color: red;
}
.video-item {
  width: 720px;
  height: 405px;
  position: relative;
}
.video-inner {
  margin-bottom: 30px;
}
.help-btn {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .video-item {
    width: 100%;
    height: 340px;
  }
}
