.btn {
  cursor: pointer;
  border: none;
  font-size: 30px;
  transition: 0.3s ease, 0.3s ease;
  margin-bottom: 50px;
  background: transparent;
   padding: 10px 30px;

}
button svg{
  width: 60px;
  height: 60px;
   transition: 0.3s ease, 0.3s ease;
  fill: rgb(102, 97, 97);
 
}
button svg:hover{
  fill: white;
}
@media (max-width: 1228px) {
  .btn {
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .btn {
    padding: 10px 50px;
  }
}
@media (max-width: 768px) {
  .btn {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .btn {
    padding: 7px 20px;
    font-size: 18px;
  }
}
