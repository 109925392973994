.anniversary-block {
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  padding-top: 15px;
}
.anniversary-title {
  color: azure;
  text-align: start;
}
.anniversary-list {
  display: flex;
  text-align: center;
  gap: 15px;
  padding: 0;
}
.anniversary-list li {
  opacity: 1;
  width: 275px;
}
.main__victim-details.anniversary p {
  text-align: center;
}
@media (max-width: 600px) {
  .anniversary-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .anniversary-title {
    font-size: 14px;
  }
}
