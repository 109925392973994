.help-link {
  align-items: center;
}
.arrow {
  width: 25px;
  height: 20px;
}
.help__title {
}

.help__text {
  margin-bottom: 50px;
}

.help__text p {
  margin-bottom: 20px;
  text-align: start;
}

.help__container a:hover {
  opacity: 1;
}
li {
  list-style: disc;
  text-align: start;
  opacity: 0.7;
}
.help-link {
  text-transform: uppercase;
  font-size: 20px;
  text-align: start;
}
