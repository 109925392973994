.btn {
	width: 48px;
	height: 48px;
	position: fixed;
	z-index: 10;
	right: 9px;
	bottom: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid var(--grey);
	color: gray;
	background-color:white;
	box-shadow: var(--box-shadow);
	transition-property: background-color;
	transition: background-color var(--transition);
	cursor: pointer;
}

.btn:hover,
.btn:focus,
.btn:active {
	background-color:rgba(128, 128, 128, 0.377);
}

@media screen and (min-width: 768px) {
	.btn {
		width: 75px;
		height: 75px;
		right: 6px;
		bottom: 50px;
	}
}

@media screen and (min-width: 1280px) {
	.btn {
		right: 6px;
		bottom: 68px;
	}
}

.arrow {
	width: 26px;
	height: 24px;
}

@media screen and (min-width:768px) {
	.arrow {
		width: 38px;
		height: 34px;
	}
}