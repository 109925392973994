.special-text {
  font-size: 46px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

@media (max-width:535px) {
  .special-text{
    font-size: 28px;
  }
}