.form__inner {
}

.form__footer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.form-input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid rgb(220, 220, 220);
  padding-left: 10px;
  font-size: 18px;
}
.form__btn {
  background-color: #6a6a6a;
  color: aliceblue;
  cursor: pointer;
  border: none;
  font-size: 25px;
  border: 2px solid white;
  transition: 0.3s ease, 0.3s ease;
  padding: 10px 10px;
}
.form__btn:hover {
  background-color: #3b3b3b;
}

textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 100px;
  padding: 7px;
  outline: none;
  border: 1px solid rgb(220, 220, 220);
  box-sizing: border-box;
  margin-bottom: 20px;
}

label {
  margin-top: 1rem;
  color: white;
  margin-bottom: 10px;
}

.error {
  color: white;
  font-size: 10px;
  margin-top: 5px;
}

.bg_modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.86);
}
.modal {
  background-color: #3b3b3b;
  border-radius: 20px;
  padding: 30px;
  position: relative;
}

.modal__title {
  color: wheat;
}
.modal__close {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  background: none;
  height: 20px;
  top: 16px;
  right: 20px;
  cursor: pointer;
}

.modal__close span {
  width: 30px;
  height: 2px;
  background-color: black;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
}

.modal__close span:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal__close span:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.error {
  color: red;
}

@media (max-width: 480px) {
  .modal {
    margin: 0 20px 0 20px;
  }
}
