.special-text {
  font-size: 2rem;
}

@media (max-width: 992px) {
  .special-text {
    font-size: 1.2rem;
  }
}
@media (max-width: 900px) {
  .special-text {
    margin-bottom: 20px;
  }
}
