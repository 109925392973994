.date {
  display: flex;
  justify-content: center;
  color: aliceblue;
  margin-bottom: 20px;
}
@media (max-width: 820px) {
  .date {
    font-size: 12px;
  }
}
@media (max-width: 629px) {
  .date {
    font-size: 10px;
  }
}
