
@font-face {
  font-family: "e-Ukraine-Regular";
  src: url("/src/Components/Fonts/e-ukraine-regular_w.woff2") format("woff2");
}

@font-face {
  font-family: "e-UkraineHead-Regular";
  src: url("/src/Components/Fonts/e-UkraineHead-Regular.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: e-Ukraine;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 77;
  }
