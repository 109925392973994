body {
  background-size: cover;
}
.proj__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.proj__title {
  text-align: start;
}
.proj__text {
  text-align: start;
}

.proj__text a {
  color: red;
}
.proj__text p {
  margin-bottom: 20px;
  line-height: 1.8;
}
.popup {
  background: #000;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  opacity: 0.9;
}
.proj__link {
  color: red;
  transition: all 0.5s;
  display: inline-block;
}
.proj__link:hover {
  color: rgb(99, 2, 2);
}
.proj-btn {
  margin-top: auto;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  .proj__container {
    height: auto;
    padding-bottom: 70px;
  }
}
@media (max-width: 750px) {
  .Proj__container {
    font-size: 14px;
  }
  .proj__title {
    margin-top: 40px;
  }
}
@media (max-width: 420px) {
  .proj__title {
    margin-top: 20px;
    font-size: 16px;
  }
  .proj__text {
    font-size: 14px;
  }
}
